import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";

import APP_STORE from "../../../res/images/APP_STORE.png";
import PLAY_STORE from "../../../res/images/PLAY_STORE.png";
import { useNavigate } from "react-router-dom";
import {
  IUserPaymentCriteria,
  IUserPaymentView,
} from "../Services/UserPaymentsServices";
import dayjs from "dayjs";

interface UserPaymentsHistoryViewProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการแสดง UI
  getAll: () => void;
  userPayments: IUserPaymentView;
  onSearch: (criteria: IUserPaymentCriteria) => void;
}

const UserPaymentsHistoryView: FC<UserPaymentsHistoryViewProps> = (props) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const searchByCiteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber === 0 ? 0 : pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IUserPaymentCriteria = {
      name: search,
      page: page,
      limit: limit,
    };
    props.onSearch(criteria);
  };

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  return (
    <Box component={"div"}>
      <Grid container spacing={2} mt={1}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography>การชำระเงิน</Typography>
          <Typography variant="h6" color="text.primary">
            รายการ
          </Typography>
        </Breadcrumbs>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    label="ชื่อลูกค้า"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Grid>
                <Grid item xs={2} textAlign={"center"} alignSelf={"center"}>
                  <Button
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={() => searchByCiteria()}
                  >
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">ชื่อลูกค้า</TableCell>
                          <TableCell align="center">Store</TableCell>
                          <TableCell align="center">แพ็คเกจล่าสุด</TableCell>
                          <TableCell align="center">วันที่ซื้อ</TableCell>
                          <TableCell align="center">วันหมดอายุ</TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {props.userPayments &&
                          props.userPayments.datas &&
                          props.userPayments.datas.length > 0 ? (
                          props.userPayments.datas.map((userPayment) => (
                            <TableRow key={userPayment.userId}>
                              <TableCell align="center">
                                {userPayment.name}
                              </TableCell>
                              <TableCell align="center">
                                {userPayment.store === "APP_STORE" ? (
                                  <Tooltip title="App Store" arrow>
                                    <img
                                      src={APP_STORE}
                                      alt="appstore"
                                      style={{ width: "20px" }}
                                    />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Play Store" arrow>
                                    <img
                                      src={PLAY_STORE}
                                      alt="playstore"
                                      style={{ width: "20px" }}
                                    />
                                  </Tooltip>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {userPayment.package}
                              </TableCell>
                              <TableCell align="center">
                                {userPayment.createAt
                                  ? dayjs(userPayment.createAt).format(
                                    "DD/MM/YYYY"
                                  )
                                  : "-"}
                              </TableCell>
                              <TableCell align="center">
                                {userPayment.expireAt
                                  ? dayjs(userPayment.expireAt).format(
                                    "DD/MM/YYYY"
                                  )
                                  : "-"}
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/payments-history/${userPayment.userId}`
                                    )
                                  }
                                >
                                  <KeyboardArrowRightIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell align="center" colSpan={5}>
                              ไม่พบข้อมูล
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        100,
                        { label: "All", value: -1 },
                      ]}
                      count={props.userPayments.total ?? 0}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        searchByCiteria(newPage);
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setLimit(e.target.value);
                        setPage(0);
                        searchByCiteria(page, e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserPaymentsHistoryView;
