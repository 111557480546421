import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import {
  IUserCustomerCriteria,
  IUserCustomerView,
} from "../Services/UserCustomersServices";

import FACEBOOK_ICON from "../../../res/images/icon_facebook.png";
import APPLE_ICON from "../../../res/images/icon_apple.png";
import GOOGLE_ICON from "../../../res/images/icon_google.png";
import LINE_ICON from "../../../res/images/icon_line.png";

interface UserCustomersListViewProps {
  getAll: () => void;
  customers: IUserCustomerView;
  searchByCiteria: (criteria: IUserCustomerCriteria) => void;
}

const UserCustomersListView: FC<UserCustomersListViewProps> = (props) => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const searchByCiteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber === 0 ? 0 : pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IUserCustomerCriteria = {
      name: search,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.searchByCiteria(criteria);
  };

  return (
    <Box component={"div"}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>ผู้ใช้งาน</Typography>
        <Typography variant="h6" color="text.primary">
          รายชื่อลูกค้า
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    label="ค้นหา"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={2} textAlign={"center"} alignSelf={"center"}>
                  <Button
                    variant="contained"
                    onClick={() => searchByCiteria(0)}
                    startIcon={<SearchIcon />}
                  >
                    ชื่อลูกค้า
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>
                        ทั้งหมด {props.customers.total} รายการ
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">ชื่อลูกค้า</TableCell>
                          <TableCell align="center">วันเกิด</TableCell>
                          <TableCell align="center">ช่วงเวลาเกิด</TableCell>
                          <TableCell align="center">เพศ</TableCell>
                          <TableCell align="center">วันที่สมัคร</TableCell>
                          <TableCell align="center">สมัครผ่านทาง</TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.customers.datas &&
                        props.customers.datas.length > 0 ? (
                          props.customers.datas.map((customer) => (
                            <TableRow key={customer.id}>
                              <TableCell align="center">
                                {customer.name}
                              </TableCell>
                              <TableCell align="center">
                                {customer.birthDate
                                  ? dayjs(customer.birthDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </TableCell>
                              <TableCell align="center">
                                {customer.birthTimeStart &&
                                customer.birthTimeEnd
                                  ? `${customer.birthTimeStart} - ${customer.birthTimeEnd}`
                                  : "-"}
                              </TableCell>
                              <TableCell align="center">
                                {customer.gender === "M" ? "ชาย" : "หญิง"}
                              </TableCell>
                              <TableCell align="center">
                                {customer.createAt
                                  ? dayjs(customer.createAt).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </TableCell>
                              <TableCell align="center">
                                <Grid
                                  container
                                  spacing={1}
                                  justifyContent={"center"}
                                >
                                  {customer.google ? (
                                    <Grid item>
                                      <img
                                        src={GOOGLE_ICON}
                                        alt="Google"
                                        height={20}
                                        width={20}
                                      />
                                    </Grid>
                                  ) : null}
                                  {customer.apple ? (
                                    <Grid item>
                                      <img
                                        src={APPLE_ICON}
                                        alt="Apple"
                                        height={20}
                                        width={20}
                                      />
                                    </Grid>
                                  ) : null}
                                  {customer.line ? (
                                    <Grid item>
                                      <img
                                        src={LINE_ICON}
                                        alt="Line"
                                        height={20}
                                        width={20}
                                      />
                                    </Grid>
                                  ) : null}
                                  {customer.facebook ? (
                                    <Grid item>
                                      <img
                                        src={FACEBOOK_ICON}
                                        alt="Facebook"
                                        height={20}
                                        width={20}
                                      />
                                    </Grid>
                                  ) : null}
                                </Grid>
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/user-customers-history/${customer.id}`
                                    )
                                  }
                                >
                                  <KeyboardArrowRightIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell align="center" colSpan={5}>
                              ไม่พบข้อมูล
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        100,
                        { label: "All", value: -1 },
                      ]}
                      count={props.customers.total ? props.customers.total : 0}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        searchByCiteria(newPage);
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setLimit(e.target.value);
                        setPage(0);
                        searchByCiteria(page, e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserCustomersListView;
