import { makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IQuestion } from "../../Questions/Services/QuestionsService";
import { IQuestionsGroup } from "../../Questions/Services/QuestionsGroupService";
import { IQuestionType } from "../../Questions/Services/QuestionTypesService";

export interface IUserCustomer {
    id: string;
    birthDate: string;
    birthTimeStart: string;
    birthTimeEnd: string;
    name: string;
    isActive: boolean;
    apple: IApple;
    facebook: IFacebook;
    google: IGoogle;
    line: ILine;
    createAt: Date;
    updateAt: Date;
    isChecked?: boolean;
    timeValue?: string;
    gender?: string;
}

export interface IUserCustomerCriteria {
    name: string;
    page: number;
    limit: number;
    birthTimeStart?: string;
    birthTimeEnd?: string;
    dateFrom?: string;
    dateTo?: string;
    gender?: string;
    platform?: string;
    id?: string;
    dateType?: string;
    notification?: string;
    isAll?: boolean;
    lang?: string;
}

export interface IUserCustomerHistoryCriteria {
    page: number;
    limit: number;
    userId?: string;
}

export interface IUserCustomerView {
    datas: IUserCustomer[];
    all: number;
    limit: number;
    page: number;
    total: number;
}

export interface IUserCustomerHistoryView {
    datas: IUserCustomerHistory[];
    all: number;
    limit: number;
    page: number;
    total: number;
}

export interface IUserCustomerHistory {
    id: string;
    user: IUserCustomer;
    question: IQuestion[];
    questionGroup: IQuestionsGroup[];
    questionType: IQuestionType[];
    // TODO : uncomment when god is ready
    // god : god;
    // wallpaper : wallpaper;
    createAt: Date;
}

export interface IApple {
    id?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    accessToken?: string;
    isDelete?: boolean;
    createAt: Date;
    updateAt: Date;
}

export interface IFacebook {
    id?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    accessToken?: string;
    isDelete?: boolean;
    createAt: Date;
    updateAt: Date;
}

export interface IGoogle {
    id?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    accessToken?: string;
    isDelete?: boolean;
    createAt: Date;
    updateAt: Date;
}

export interface ILine {
    id?: string;
    displayName?: string;
    userId: string;
    accessToken?: string;
    refreshToken?: string;
    pictureUrl?: string;
    isDelete?: boolean;
    createAt: Date;
    updateAt: Date;
}

class UserCustomersService {
    customers: IUserCustomerView = {} as IUserCustomerView;
    customersHistory: IUserCustomerHistoryView;
    constructor() {
        this.customersHistory = {} as IUserCustomerHistoryView;
        makeAutoObservable(this);

    }

    async getAll() {
        try {
            const response = await apiService.get("/users");
            if (response.length > 0) {
                this.customers = {
                    datas: response.filter((x: IUserCustomer) => x.isActive),
                    all: response.length,
                    limit: 10,
                    page: 1,
                    total: response.length
                };
            } else {
                this.customers = {
                    datas: [],
                    all: 0,
                    limit: 10,
                    page: 1,
                    total: 0
                };
            }

            return this.customers;
        } catch (error) {
            throw error;
        }
    }

    async getOne(id: string) {
        try {
            const response = await apiService.get(`/customers/${id}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getUserHistory(id: string) {
        try {
            const response = await apiService.get(`/user-histories/user/${id}`);
            if (response.length > 0) {
                this.customersHistory = {
                    datas: response,
                    all: response.length,
                    limit: 10,
                    page: 1,
                    total: response.length
                };
            } else {
                this.customersHistory = {
                    datas: [],
                    all: 0,
                    limit: 10,
                    page: 1,
                    total: 0
                };
            }
            return this.customersHistory;
        } catch (error) {
            throw error;
        }
    }

    async getCustomerByCriteria(criteria: IUserCustomerCriteria) {
        try {
            const response = await apiService.post(`/users/criteria`, criteria);

            this.customers = {
                datas: response.data.filter((x: IUserCustomer) => x.isActive),
                all: response.length,
                limit: response.limit,
                page: response.page,
                total: response.total
            };

            return this.customers;
        } catch (error) {
            throw error;
        }
    }


    async getCustomerHistoryByCriteria(criteria: IUserCustomerHistoryCriteria) {
        try {
            const response = await apiService.post(`/user-histories/criteria`, criteria);
            if (response.data.length > 0) {
                this.customersHistory = {
                    datas: response.data,
                    all: response.all,
                    limit: response.limit,
                    page: response.page,
                    total: response.total
                };
            } else {
                this.customersHistory = {
                    datas: [],
                    all: 0,
                    limit: criteria.limit,
                    page: criteria.page,
                    total: 0
                };
            }
            return this.customersHistory;
        } catch (error) {
            throw error;
        }
    }
}

const userCustomersService = new UserCustomersService();
export default userCustomersService;