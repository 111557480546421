import { FC, useEffect, useState } from "react";
import UserPaymentsServices, {
  IUserPaymentCriteria,
} from "../Services/UserPaymentsServices";
import UserPaymentsListView from "../Views/UserPaymentsListView";
import { IUserPaymentView } from "../Services/UserPaymentsServices";
import { enqueueSnackbar } from "notistack";

interface UserPaymentsListControllerProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการจัดการข้อมูล
}

const UserPaymentsListController: FC<UserPaymentsListControllerProps> = (
  props
) => {
  const [userPayments, setUserPayments] = useState<IUserPaymentView>();

  useEffect(() => {
    const criteria: IUserPaymentCriteria = {
      name: "",
      page: 0,
      limit: 10,
    };
    searchByCriteria(criteria);
  }, []);

  const fetchUserPayments = async () => {
    try {
      const datas = await UserPaymentsServices.getAll();
      if (datas) {
        setUserPayments(datas as IUserPaymentView);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const searchByCriteria = async (criteria: IUserPaymentCriteria) => {
    try {
      const datas = await UserPaymentsServices.getUserPaymentByCriteria(
        criteria
      );
      if (datas) {
        setUserPayments(datas as IUserPaymentView);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  return (
    <UserPaymentsListView
      getAll={fetchUserPayments}
      userPayments={userPayments ?? ({} as IUserPaymentView)}
      onSearch={(criteria: IUserPaymentCriteria) => {
        searchByCriteria(criteria);
      }}
    />
  );
};

export default UserPaymentsListController;
