import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import {
  NoteAdd as NoteAddIcon,
  StarBorder as StarBorderIcon,
  Star as StarIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { IGodCriteria, IGodView } from "../Services/GodsService";
import dayjs from "dayjs";

interface GodsListViewProps {
  gods: IGodView;
  onSearch: (criteria: IGodCriteria) => void;
  onUpdateFavorite: (id: number, isFavorite: boolean) => void;
}

const GodsListView: FC<GodsListViewProps> = (props) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isFavorite, setIsFavorite] = useState(false);

  const searchByCiteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber === 0 ? 0 : pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IGodCriteria = {
      title: title,
      page: pageNumber,
      limit: rowLimit ?? 10,
      isFavorite: isFavorite,
    };
    props.onSearch(criteria);
  };

  return (
    <Box component={"div"}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>เทพเจ้า</Typography>
        <Typography variant="h6" color="text.primary">
          รายการ
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="ชื่อ"
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={4} alignSelf={"center"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isFavorite}
                        onChange={(e) => {
                          setIsFavorite(e.target.checked);
                        }}
                      />
                    }
                    label="ข้อมูลแสดงหน้าแรก"
                  />
                </Grid>
                <Grid item xs={2} textAlign={"center"} alignSelf={"center"}>
                  <Button
                    variant="contained"
                    onClick={() => searchByCiteria(0)}
                    startIcon={<SearchIcon />}
                  >
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography>ทั้งหมด {props.gods.total} รายการ</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        startIcon={<NoteAddIcon />}
                        onClick={() => navigate("/god-create")}
                      >
                        สร้าง
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>ชื่อ</TableCell>
                          <TableCell align="center">แสดงหน้าแรก</TableCell>
                          <TableCell align="center">บันทึก</TableCell>
                          <TableCell align="center">แก้ไข</TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.gods.datas && props.gods.datas.length > 0 ? (
                          props.gods.datas.map((god) => (
                            <TableRow
                              key={god.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="center">
                                {god.image && god.image.name ? (
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}/uploads/${god.image?.name}`}
                                    alt={god.title}
                                    width={50}
                                  />
                                ) : (
                                  god.title
                                )}
                              </TableCell>

                              <TableCell component="th" scope="row">
                                {god.title}
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  onClick={() => {
                                    props.onUpdateFavorite(
                                      god.id!,
                                      god.isFavorite ? false : true
                                    );
                                  }}
                                >
                                  {god.isFavorite ? (
                                    <StarIcon />
                                  ) : (
                                    <StarBorderIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                              <TableCell align="center">
                                <Typography fontWeight={"bold"}>
                                  {god.createBy ? god.createBy.firstName : ""}
                                </Typography>
                                <Typography>
                                  {god.createAt
                                    ? dayjs(god.createAt).format(
                                      "DD/MM/YYYY HH:mm"
                                    )
                                    : ""}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography fontWeight={"bold"}>
                                  {god.updateBy ? god.updateBy.firstName : ""}
                                </Typography>
                                <Typography>
                                  {god.updateAt
                                    ? dayjs(god.updateAt).format(
                                      "DD/MM/YYYY HH:mm"
                                    )
                                    : ""}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    navigate(`/god-edit/${god.id}`)
                                  }
                                >
                                  <ChevronRightIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell align="center" colSpan={5}>
                              ไม่พบข้อมูล
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        100,
                        { label: "All", value: -1 },
                      ]}
                      count={props.gods.total ?? 0}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        searchByCiteria(newPage);
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setLimit(e.target.value);
                        setPage(0);
                        searchByCiteria(page, e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GodsListView;
